import {request} from "./network";

// 登录
export function login(data) {
    return request({
        method: "post",
        url: "/login/loginByIdCard",
        params:data,
    });
}
