<template>
    <div style="min-width: 2046px; height: 100%; min-height: 1024px; background-color: #f2edf4">
        <div class="container">
            <div id="login_form">
                <h3 style="
            color: #5c307d;
            font-size: 28px;
            text-align: center;
            margin-bottom: 30px;
          "
                >
                    考试二维码展示系统
                </h3>
            </div>
            <el-form :model="loginForm" :rules="loginFormRules" ref="loginFormRef">
                <el-form-item
                        prop="idCard"
                        class="f-f1 item-ifo"
                        style="position: relative"
                >
                    <el-input
                            class="name"
                            v-model="loginForm.idCard"
                            placeholder="请输入身份证号"
                            maxlength="20"
                            prefix-icon="el-icon-user-solid"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button
                            type="primary"
                            class="login"
                            style="width: 450px"
                            @click="submitForm()"
                    >登录
                    </el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
    import {login} from "r/qrCode/login.js";
    import {sfzNumCheck} from "common/utils.js";

    export default {
        name: "Login",
        data() {
            return {
                loginForm: {
                    idCard: "",
                },
                // 表单验证规则对象
                loginFormRules: {
                    // 对用户名进行校验
                    idCard: [
                        {required: true, message: "请输入身份证号", trigger: "blur"},
                        // {
                        //   validator: function (rule, value, callback) {
                        //     if (sfzNumCheck(value) === false) {
                        //       callback(new Error("身份证号格式错误"));
                        //     } else {
                        //       callback();
                        //     }
                        //   },
                        //   trigger: "blur",
                        // },
                    ],
                },
            };
        },
        methods: {
            // 表单验证
            submitForm() {
                this.$refs.loginFormRef.validate(async (valid) => {
                    // 验证用户名和准考证号是否符合规定
                    if (!valid) return;
                    // 发送请求的操作
                    let {data: res} = await login(this.loginForm);
                    console.log(res, "data");
                    // 根据返回的状态码做想关的判断
                    if (res.code !== 200) return this.$message.error(data.message);
                    this.$message.success("登录成功！");

                    window.localStorage.setItem("qrCode-token", res.data.tokenHead+' '+res.data.token);
                    // 存储idCard
                    window.localStorage.setItem("qrCode-idCard", this.loginForm.idCard);
                    // 重置表单
                    this.$refs.loginFormRef.resetFields();
                    //指定跳转地址
                    await this.$router.push({path:"/qrCode/index/lists"});
                });
            },
        },
    };
</script>

<style scoped lang="scss">
    @import "a/css/common";

    .container {
        width: 660px;
        position: fixed;
        top: 30%;
        left: 50%;
        transform: translate(-50%, 0);
        right: 0;
        z-index: 1;
        display: flex;
        justify-content: center;

    }

    .name ::v-deep .el-input__inner {
        width: 100%;
        height: 100%;
        padding-left: 40px;
        position: absolute;
        left: 0;
        border: 0;
    }

    .name ::v-deep .el-input__prefix {
        left: 0;
        font-size: 20px;

        .el-input__icon::before {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
</style>
