import axios from "axios";
import {
    SERVER_URL,
    DEVELOPMENT_SERVER_URL
} from '../../config/server'

import {
    Notification,
    Message
} from "element-ui";
import router from "../../router";

export function request(config) {
    const instance = axios.create({
        baseURL: process.env.NODE_ENV === "development" ?
            DEVELOPMENT_SERVER_URL :
            SERVER_URL,
        timeout: 60 * 1000,
    });

    instance.interceptors.request.use(
        (config) => {
            let token = localStorage.getItem("qrCode-token");
            if (token) {
                config.headers.Authorization = token;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    instance.interceptors.response.use(
        (res) => {
            return res;
        },
        (error) => {
            Message.closeAll();
            return Promise.reject(error);
        }
    );

    return instance(config);
}


// 合并多个请求
export function allRequest(arrRequest) {
    return axios.all(arrRequest);
}
