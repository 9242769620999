<template>
    <div style="min-width: 2046px; height: 100%; min-height: 1024px; background-color: #f2edf4">
        <div class="container">
            <div id="login_form">
                <h3 style="
            color: #5c307d;
            font-size: 28px;
            text-align: center;
            margin-bottom: 30px;
          "
                >
                   考生调试助手
                </h3>
            </div>
            <el-form :model="loginForm" :rules="loginFormRules" ref="loginFormRef">
                <el-form-item
                        prop="name"
                        class="f-f1 item-ifo"
                        style="position: relative"
                >
                    <el-input
                            class="name"
                            v-model="loginForm.name"
                            placeholder="请输入身份证号"
                            maxlength="20"
                            prefix-icon="el-icon-user-solid"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button
                            type="primary"
                            class="login"
                            style="width: 450px"
                            @click="submitForm()"
                    >登录
                    </el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>


<script>
// import {authMixin} from "mixins/admin/auth";
import { login } from "r/index/login.js";
import { mobileCheck } from "common/utils.js";

export default {
  name: "Login",
  // mixins: [authMixin],
  data() {
    return {
     loginForm: {
                    name: "",
                  password: "123456",
                },
                // 表单验证规则对象
                loginFormRules: {
                    // 对用户名进行校验
                    name: [
                        {required: true, message: "请输入身份证号", trigger: "blur"},
                        {
                          validator: function (rule, value, callback) {
                            console.log(value)
                            console.log(/\s/g.test(value))
                            if (/\s/g.test(value) === true) {
                              callback(new Error("身份证号不能有空格"));
                            } else {
                              callback();
                            }
                          },
                          trigger: "blur",
                        },
                    ],
                },
    };
  },
  methods: {
    // 表单验证
    submitForm() {
      this.$refs.loginFormRef.validate(async (valid) => {

          //this.$message.success("调试助手于6月16日下午14:00开放！");
          //return false;

        // 验证用户名和准考证号是否符合规定
        if (!valid) return;
        // console.log(this.loginForm, "上传的用户名");

        // 发送请求的操作
        let { data } = await login(this.loginForm);
        console.log(data, "data");
        // 根据返回的状态码做想关的判断
        if (data.code != 200) return this.$message.error(data.message);

        this.$message.success("登录成功！");
        // data = data.data;
        // console.log(data, "登录返回数据");
        // token存储
        window.localStorage.setItem("index-token", data.data.token);

        // 存储用户名
        window.localStorage.setItem("index-userInfo", this.loginForm.name);
        // console.log(this.loginForm,"返回loginForm")

        // 存储examId
        // window.localStorage.setItem("index-examId", data.examUuid);

        // 存储teacherId
        window.localStorage.setItem("index-teacherId", data.data.teacherId);

        // 重置表单
        this.$refs.loginFormRef.resetFields();

        // 使用编程式导航实现跳转

        //点击之后缓存examId用来保持上个页面保存的数据
        window.localStorage.setItem("holdId", data.data.examId);
        window.localStorage.setItem("subjectId", data.data.subjectId);
        window.localStorage.setItem("examUuid", data.data.examUuid);
        window.localStorage.setItem("subjectUuid", data.data.subjectUuid);
        window.localStorage.setItem("subjectName", data.data.subjectName);
        window.localStorage.setItem("examName", data.data.examName);



        // window.localStorage.setItem("useComputer", JSON.stringify(data.data.useComputer));
        // window.localStorage.setItem("spareComputer", JSON.stringify(data.data.spareComputer));
        //点击跳转至上次浏览页面
        // this.$router.go(-1)
        //指定跳转地址
        await this.$router.push({
          name: "VideoList",
           query: {
             subjectId: data.data.subjectId,
             examUuid: data.data.examUuid,
             subjectUuid: data.data.subjectUuid,
             subjectName: data.data.subjectName,
             examName: data.data.examName,
          //   // useComputer: data.data.useComputer,
          //   // spareComputer: data.data.spareComputer,
          },
        });
        // await this.$router.push("./examination/lists");
      });
    },
  },
};
</script>

<style scoped lang="scss">
 @import "a/css/common";

    .container {
        width: 660px;
        position: fixed;
        top: 30%;
        left: 50%;
        transform: translate(-50%, 0);
        right: 0;
        z-index: 1;
        display: flex;
        justify-content: center;

    }

    .name ::v-deep .el-input__inner {
        width: 100%;
        height: 100%;
        padding-left: 40px;
        position: absolute;
        left: 0;
        border: 0;
    }

    .name ::v-deep .el-input__prefix {
        left: 0;
        font-size: 20px;

        .el-input__icon::before {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
</style>
